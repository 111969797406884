import * as React from "react";
import "./About.css"
import aboutProfilePic from "../assets/images/profile2.jpg"


export const About = () => {
    return (
        <section className="about" id="about">
            <h2 className="heading"><i className="fas fa-user-alt"></i> About <span>Me</span></h2>

            <div className="row">

                <div className="image">
                    <img draggable="false" className="tilt" src={aboutProfilePic} alt="" />
                </div>
                <div className="content">
                    <h3>I'm Ashutosh</h3>
                    <span className="tag">Cyber Security Professional</span>

                    <p>I'm Ashutosh Upadhyay, a Security Engineer with a passion for protecting critical systems.

With a background in Computer Engineering, I leverage my cloud and DevOps security expertise to safeguard Raise Financial Services' infrastructure and applications.

I'm a skilled developer proficient in ReactJS, Python, and Bash, constantly striving to build innovative security solutions.</p>

                    <div className="box-container">
                        <div className="box">
                            <p><span> age: </span> 21</p>
                            <p><span> phone : </span> +91 XXX-XXX-XXXX</p>
                        </div>
                        <div className="box">
                            <p><span> email : </span> ashutoshupadhyay780@gmail.com</p>
                            <p><span> place : </span> Mumbai, India - 412206</p>
                        </div>
                    </div>

                    <div className="resumebtn">
                        <a href="https://linkedin.com/in/ashutoshuy/" className="btn"><span>Linkedin</span>
                            <i className="fas fa-chevron-right"></i>
                        </a>
                    </div>

                </div>
            </div>
        </section>
    );
};
export default About;