import React from 'react';
import "./Navbar.css"

const Navbar = () => {
  return (
    <header>
      <a href="/" className="logo"><i className="fab fa-node-js"></i> Ashutosh</a>

      <div id="menu" className="fas fa-bars"></div>
      <nav className="navbar">
        <ul>
          <li><a className="active" href="/#">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#skills">Skills</a></li>
          <li><a href="#education">Education</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="#experience">Experience</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
