// Blog.js
import React, { useState, useEffect } from 'react';
import './Blog.css'; // Import the CSS file

const Blog = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear interval when the component unmounts or countdown reaches 0
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Redirect to the external blog URL after 5 seconds
    if (countdown === 0) {
      window.location.href = 'http://blog.ashutoshuy.co';
    }
  }, [countdown]);

  return (
    <div className="blog-container">
      <h1 className="blog-title">Redirecting to blog.ashutoshuy.co...</h1>
      <p className="countdown-text">Countdown: {countdown}</p>
      {countdown > 0 && (
        <button className="back-button" onClick={() => (window.location.href = '/')}>
          Go Back   
        </button>
      )}
    </div>
  );
};

export default Blog;
