import * as React from "react";
import './Skills.css'

export const Skills = () => {
  return (
    <section className="skills" id="skills">

      <h2 className="heading"><i className="fas fa-laptop-code"></i> Skills & <span>Abilities</span></h2>

      <div className="container">
        <div className="row" id="skillsContainer">


          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/python.png" alt="" />
              <span>Python</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/bash.png" alt="" />
              <span>Bash</span>
            </div>
          </div>


          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/react-native.png" alt="" />
              <span>React Js</span>
            </div>
          </div>





          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/tailwindcss.png" alt="" />
              <span>TailWind</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/nodejs.png" alt="" />
              <span>Node</span>
            </div>
          </div>


          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/mongodb.png" alt="" />
              <span>Mongo DB</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/mysql.png" alt="" />
              <span>MySql</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/docker.png" alt="" />
              <span>Docker</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/linux.png" alt="" />
              <span>Linux</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/amazon-web-services.png" alt="" />
              <span>AWS</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/google-cloud.png" alt="" />
              <span>GCP</span>
            </div>
          </div>


          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/jenkins.png" alt="" />
              <span>Jenkins</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/grafana.png" alt="" />
              <span>Grafana</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/prometheus-app.png" alt="" />
              <span>prometheus</span>
            </div>
          </div>


          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/cloudflare.png" alt="" />
              <span>CloudFlare</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/kubernetes.png" alt="" />
              <span>Kubernetes</span>
            </div>
          </div>


          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/metasploit.png" alt="" />
              <span>Metasploit</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/html.png" alt="" />
              <span>Html/Css</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/javascript.png" alt="" />
              <span>Javascript</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/selenium-test-automation.png" alt="" />
              <span>selenium</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/office-365.png" alt="" />
              <span>MS Office</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/kotlin.png" alt="" />
              <span>Kotlin</span>
            </div>
          </div>

          <div className="bar">
            <div className="info">
              <img src="https://img.icons8.com/color/48/000000/hacking.png" alt="" />
              <span>Ethical Hacking</span>
            </div>
          </div>

        </div>
      </div>
    </section>

  );
};
export default Skills;