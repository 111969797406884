import * as React from "react";
import "./Education.css"
import collegeImages from "../assets/images/educat/universal.jpeg"
import schoolImages from "../assets/images/educat/bnnCollege.jpg"

export const Education = () => {
    return (
        <section className="education" id="education">

            <h1 className="heading"><i className="fas fa-graduation-cap"></i> My <span>Education</span></h1>

            <p className="qoute">Education is not the learning of facts, but the training of the mind to think.</p>

            <div className="box-container">

                <div className="box">
                    <div className="image">
                        <img draggable="false" src={collegeImages} alt=""/>
                    </div>
                    <div className="content">
                        <h3>Bachelor of Engineering in Computer Science</h3>
                        <p>Universal College of Engineering | Mumbai University    </p>
                        <h4>2020-2024 | Pursuing</h4>
                    </div>
                </div>


                <div className="box">
                    <div className="image">
                        <img draggable="false" src={collegeImages} alt=""/>
                    </div>
                    <div className="content">
                        <h3>Honors in CyberSecurity</h3>
                        <p>Universal College of Engineering | Mumbai University    </p>
                        <h4>2022-2024 | Pursuing</h4>
                    </div>
                </div>

                <div className="box">
                    <div className="image">
                        <img draggable="false" src={schoolImages} alt=""/>
                    </div>
                    <div className="content">
                        <h3>HSC Science</h3>
                        <p>B.N.N College | Maharsahtra State Board</p>
                        <h4>2018-2020 | Completed</h4>
                    </div>
                </div>

            </div>
        </section>

    );
};
export default Education;