// HeroSection.js
import React from 'react';
import './Hero.css';
import heroIMage from "../assets/images/hero.png"
import TypewriterComponent from 'typewriter-effect';
import ParticlesEffect from './ParticleComponent';  



const HeroSection = () => {
  return (
    <section className="home" id="home">
        <ParticlesEffect />
        
      <div className="content">
        <h2>Hi There,<br /> I'm Ashutosh <span>Upadhyay</span></h2>
        <p>i am into <span className="typing-text"> <TypewriterComponent onInit={(typewriter) => {
          typewriter
            .start()
            .typeString("Hacking")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Cybersecurity")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Cloud Security")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Web Development")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Security Assesment")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Scripting")
            .pauseFor(1000)
            .deleteAll()
            .typeString("DevOps")
            .pauseFor(1000)
            .deleteAll()
            .typeString("DevSecOps")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Security Training")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Co-operate Training")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Technical Consultant")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Cybersecurity")
        }}
        />     </span></p>
        <a href="#about" className="btn"><span>About Me</span><i className="fas fa-arrow-circle-down"></i></a>
        <div className="socials">
          <ul className="social-icons">
            <li><a className="linkedin" aria-label="LinkedIn" href="https://www.linkedin.com/in/ashutoshuy/" ><i className="fab fa-linkedin"></i></a></li>
            <li><a className="github" aria-label="GitHub" href="https://github.com/ashutoshuy" ><i className="fab fa-github"></i></a></li>
            <li><a className="twitter" aria-label="Twitter" href="https://twitter.com/ashutoshuy" ><i className="fab fa-twitter"></i></a></li>
            <li><a className="telegram" aria-label="Telegram" href="https://t.me/lifecode5" ><i className="fab fa-telegram-plane"></i></a></li>
            <li><a className="instagram" aria-label="Instagram" href="https://www.instagram.com/ashutoshuy"><i className="fab fa-instagram" ></i></a></li>
            <li><a className="dev" aria-label="Dev" href="https://dev.to/ashutoshuy" ><i className="fab fa-dev"></i></a></li>
          </ul>
        </div>
      </div>

      <div className="image">
        <img draggable="false" className="tilt" src={heroIMage} alt="" />
      </div>
    </section>
  );
};

export default HeroSection;
