// pages/LandingPage.js
import React from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import Skills from '../components/Skills';
import Education from '../components/Education';
import Experience from '../components/Experience';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <div>
      <h1>Landing Page</h1>
      <Hero />
      <About />
      <Skills />
      <Education />
      {/* <Work /> */}
      <Experience />
      <Contact />
      <Footer />
    </div>
  );
};

export default LandingPage;
