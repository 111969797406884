import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Your Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-C3LPZ2DTF0';
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-C3LPZ2DTF0');
    };

    return () => {
      // Cleanup script when the component is unmounted
      document.head.removeChild(script);    
    };
  }, []);

  return (
    <Helmet>
      {/* Add any additional meta tags or head elements here */}
    </Helmet>
  );
};

export default GoogleAnalytics;
