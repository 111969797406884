import * as React from "react";
import "./Experience.css"

export const Experience = () => {
    return (
        <section className="experience" id="experience">

            <h2 className="heading"><i className="fas fa-briefcase"></i> Experience </h2>

            <div className="timeline">

                <div className="container right">
                    <div className="content">
                        <div className="tag">
                            <h2>Raise Financial Services</h2>
                        </div>
                        <div className="desc">
                            <h3>Senior Security Engineer</h3>
                            <p>Feb 2024 - Present</p>
                        </div>
                    </div>
                </div>

                <div className="container left">
                    <div className="content">
                        <div className="tag">
                            <h2>Raise Financial Services</h2>
                        </div>
                        <div className="desc">
                            <h3>Associate Security Engineer</h3>
                            <p>June 2023 - Feb 2024</p>
                        </div>
                    </div>
                </div>



                <div className="container right">
                    <div className="content">
                        <div className="tag">
                            <h2>AIIITS</h2>
                        </div>
                        <div className="desc">
                            <h3> Security & DevOps Trainer | Part Time</h3>
                            <p>january 2023 - June 2023</p>
                        </div>
                    </div>
                </div>

                <div className="container left">
                    <div className="content">
                        <div className="tag">
                            <h2>Cyber Secured India</h2>
                        </div>
                        <div className="desc">
                            <h3>Empanneled Speaker | Part Time</h3>
                            <p>january 2023 - June 2023</p>
                        </div>
                    </div>
                </div>

                <div className="container right">
                    <div className="content">
                        <div className="tag">
                            <h2>Raise Financial Services</h2>
                        </div>
                        <div className="desc">
                            <h3>DevOps Security Analyst | Internship</h3>
                            <p>June 2022 - December 2022</p>
                        </div>
                    </div>
                </div>

                <div className="container left">
                    <div className="content">
                        <div className="tag">
                            <h2>Chitale Bandhu Mithai wale</h2>
                        </div>
                        <div className="desc">
                            <h3>Cybersecurity Consultant | Internship</h3>
                            <p>March 2022 - May 2022</p>
                        </div>
                    </div>
                </div>

                <div className="container right">
                    <div className="content">
                        <div className="tag">
                            <h2>KeyCyber</h2>
                        </div>
                        <div className="desc">
                            <h3>Cyber Crime Investigation & Research | Internship</h3>
                            <p>August 2021 - December 2021</p>
                        </div>
                    </div>
                </div>

                <div className="container left">
                    <div className="content">
                        <div className="tag">
                            <h2>Guguram Police</h2>
                        </div>
                        <div className="desc">
                            <h3>Cyber Security | Internship</h3>
                            <p>June 2021 - July 2021</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="morebtn">
                <a href="/#experience" className="btn"><span>Started Carreer in 2021</span>
                </a>
            </div>



        </section >

    );
};
export default Experience;